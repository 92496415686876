import React, { useEffect, useState } from "react";
import "../Styles/Doctors.css";
import DoctorCard from "../Components/DoctorCard";
import logo from "../Assets/banner/docBanner.png";

function Specialists() {
  const [doctorsList, setDoctorsList] = useState();

  useEffect(() => {
    getDoctorsList();
  }, []);

  const getDoctorsList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://aims-ajk.com/dashboard/api/doctors", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDoctorsList(result);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
      }}
    >
      <div className="doctorpage-section">
        <div className="dt-title-content">
          <h3 className="dt-title">OUR LEADING MEDICAL PROFESSIONALS</h3>

          <p className="dt-description">
            Meet our exceptional team of specialist doctors, dedicated to
            providing top-notch healthcare services at AIMS. Trust in their
            knowledge and experience to lead you towards a healthier and happier
            life.
          </p>
        </div>
        <div style={{ width: "100%", height: "70vh", marginBottom:"30px" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={logo}
            alt="News"
          />
        </div>

        <div className="dt-cards-content">
          {doctorsList?.doctors?.map((item, index) => {
            return (
              <DoctorCard
                key={index}
                img={item.image} // Make sure the property matches the API response
                name={item.name}
                title={item.title}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Specialists;
