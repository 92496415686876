import React from "react";
import "../../Styles/HomeCompStyles/HomeHeroComp.css";
import Doctor from "../../Assets/banner/banner.png";
import { useNavigate } from "react-router-dom";

function HomeHeroComp() {
  const navigate = useNavigate();
  return (
    <div className="homeHero-section">
      <div className="homeHero-title-content">
        <h3 className="homeHero-title-top" style={{color: "white"}}>OUR MISSION</h3>
        <p className="homeHero-description">
          Abbas Institute of Medical Sciences is committed to maintain and
          improve services to the community according to the highest attainable
          quality standards and to provide a suitable environmentfor educating
          and training of headlthcare professionals.
        </p>
        <button
          className="homeHero-btn"
          onClick={() => {
            navigate("/about");
          }}
          style={{width: "200px"}}
        >
          More Info
        </button>
      </div>
      <div className="homeHero-img-content">
        <img className="homeHero-image1" src={Doctor} alt="Doctor" />
      </div>
    </div>
  );
}

export default HomeHeroComp;
