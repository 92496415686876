// src/Pages/Contact.js
import React from "react";
import AboutHero from "../Components/AboutComp/AboutHero";
import "../Styles/AboutPage/aboutHome.css"

const aboutArray = [
  {
    id: 1,
    heading: "INTRODUCTION",
    description: [
      {
        subHeading: "",
        para: "The Abbas Institute of Medical Sciences (AIMS) is a prominent healthcare institution located in Muzaffarabad, the capital of Azad Jammu & Kashmir, Pakistan. Established in 1992, AIMS is a teaching hospital affiliated with Azad Jammu Kashmir Medical College. It plays a crucial role in providing comprehensive healthcare services to the local population and serves as a vital training ground for medical professionals. This essay provides an in-depth look at the history, structure, services, and impact of AIMS Hospital, highlighting its significance in the region's healthcare landscape.",
      },
      {
        subHeading: "History",
        para: "AIMS Hospital was established in 1992, making it one of the oldest and most reputable healthcare institutions in Azad Jammu & Kashmir. The hospital was founded with the mission to provide quality medical care to the residents of Muzaffarabad and surrounding areas, which historically faced challenges in accessing advanced healthcare services. Over the years, AIMS has grown in size and capabilities, evolving into a major medical and teaching facility.",
      },
      {
        subHeading: "Teaching Hospital Status",
        para: "In 2012, AIMS gained recognition as a teaching hospital when it became affiliated with Azad Jammu Kashmir Medical College. This affiliation was a significant milestone, enhancing the hospital's capacity to offer postgraduate medical education and training. The Pakistan Medical and Dental Council (PMDC) and the College of Physicians and Surgeons Pakistan (CPSP) recognize AIMS for postgraduate residency programs in various medical disciplines, further cementing its role as an essential institution for medical education in the region​ ",
      },
    ],
  },
  {
    id: 2,
    heading: "Facilities and Services",
    description: [
      {
        subHeading: "Emergency Department",
        para: "AIMS boasts a 24/7 emergency department that provides critical care services to patients. The emergency department is equipped with modern medical technology and staffed by experienced healthcare professionals who are trained to handle a wide range of medical emergencies. This facility is crucial in a region prone to natural disasters and other emergencies, providing timely and effective medical interventions.",
      },
      {
        subHeading: "Inpatient and Outpatient Services",
        para: "The hospital has a capacity of 350 beds, catering to both inpatient and outpatient needs. AIMS offers comprehensive medical services across various specialties, including internal medicine, surgery, pediatrics, obstetrics and gynecology, and more. The hospital's outpatient clinics are designed to provide accessible and efficient medical care to patients, reducing wait times and improving overall patient satisfaction.",
      },
    ],
  },
  {
    id: 3,
    heading: "Specialized Departments",
    description: [
      {
        subHeading: "Oncology",
        para: "AIMS has a dedicated oncology department that provides diagnosis, treatment, and care for cancer patients. The hospital is part of a public-private partnership initiative that offers free treatment to poor cancer patients in Islamabad, Gilgit-Baltistan, and Azad Kashmir. This program, supported by the Ministry of National Health Services and a pharmaceutical firm, aims to alleviate the financial burden on patients and ensure access to life-saving treatments​ (www.marham.pk)​.",
      },
      {
        subHeading: "Cardiology",
        para: "The cardiology department at AIMS is equipped with advanced diagnostic and therapeutic tools to manage heart diseases. The department offers services such as electrocardiography (ECG), echocardiography, stress testing, and cardiac catheterization, providing comprehensive cardiac care to patients.",
      },
      {
        subHeading: "Pediatrics",
        para: "The pediatrics department provides specialized care for infants, children, and adolescents. The department offers services ranging from routine check-ups and vaccinations to the treatment of complex pediatric conditions. The pediatricians at AIMS are well-trained and experienced in handling a wide range of child health issues.",
      },
      {
        subHeading: "Gynecology and Obstetrics",
        para: "The gynecology and obstetrics department at AIMS offers comprehensive care for women, including prenatal, antenatal, and postnatal care. The department is equipped with modern facilities to handle normal and high-risk pregnancies, ensuring the safety and well-being of both mothers and babies.",
      },
      {
        subHeading: "Surgical Services",
        para: "AIMS has a well-equipped surgical department that offers a range of surgical procedures, from minor surgeries to major operations. The hospital's surgical team comprises experienced surgeons who are skilled in various surgical specialties, including general surgery, orthopedic surgery, neurosurgery, and more.",
      },
      {
        subHeading: "Diagnostic Services",
        para: "The hospital is equipped with state-of-the-art diagnostic facilities, including radiology, pathology, and laboratory services. These facilities enable accurate and timely diagnosis of medical conditions, facilitating effective treatment and management of patients.",
      },
      {
        subHeading: "Community Outreach and Public Health Initiatives",
        para: "AIMS is actively involved in community outreach and public health initiatives aimed at improving the overall health and well-being of the local population. The hospital organizes health camps, awareness programs, and vaccination drives to educate the community about preventive healthcare measures and provide essential medical services to underserved areas.",
      },
    ],
  },
  {
    id: 4,
    heading: "Impact on Healthcare in the Region",
    description: [
      {
        subHeading: "Improving Access to Quality Healthcare",
        para: "AIMS has significantly improved access to quality healthcare services in Muzaffarabad and surrounding areas. Before the establishment of AIMS, residents of the region had to travel long distances to major cities for advanced medical care. The presence of a well-equipped and staffed hospital in Muzaffarabad has reduced the need for such travel, making healthcare more accessible and convenient for the local population.",
      },
      {
        subHeading: "Training and Development of Medical Professionals",
        para: "As a teaching hospital, AIMS plays a crucial role in the training and development of medical professionals. The hospital's affiliation with Azad Jammu Kashmir Medical College ensures that medical students receive hands-on training and practical experience in a real-world healthcare setting. This exposure is invaluable in preparing future doctors, nurses, and other healthcare providers to meet the region's healthcare needs effectively",
      },
      {
        subHeading: "Contributions to Medical Research",
        para: "AIMS is also involved in medical research, contributing to the advancement of medical knowledge and practices. The hospital's research initiatives focus on addressing health challenges specific to the region, such as infectious diseases, maternal and child health, and non-communicable diseases. By conducting research and disseminating findings, AIMS helps improve healthcare practices and outcomes in Azad Jammu & Kashmir and beyond.",
      },
    ],
  },
  {
    id: 5,
    heading: "Challenges and Future Directions",
    description: [
      {
        subHeading: "Challenges",
        para: "Despite its many achievements, AIMS faces several challenges in delivering healthcare services. One of the primary challenges is the limited availability of resources and funding. As a public hospital, AIMS relies heavily on government funding and donations to maintain and upgrade its facilities and services. Ensuring a steady flow of resources is crucial to sustain the hospital's operations and expand its capabilities.",
      },
      {
        subHeading: "",
        para: "Another challenge is the retention of skilled healthcare professionals. Like many healthcare institutions in developing regions, AIMS faces difficulties in attracting and retaining experienced medical staff. Competitive salaries, professional development opportunities, and conducive working conditions are essential to address this issue and ensure the hospital has a stable workforce.",
      },
      {
        subHeading: "Future Directions",
        para: "To overcome these challenges and continue its mission of providing quality healthcare, AIMS has identified several future directions. These include:",
      },
      {
        subHeading: "",
        para: "Strengthening Partnerships: AIMS aims to strengthen partnerships with government agencies, non-governmental organizations, and private sector entities to secure additional funding and resources. Collaborative efforts can help enhance the hospital's infrastructure, expand its services, and support ongoing medical research.",
      },
      {
        subHeading: "",
        para: "Enhancing Medical Education: AIMS plans to further enhance its medical education programs by incorporating advanced training modules and technologies. This includes the establishment of simulation labs, telemedicine facilities, and continuing medical education programs for healthcare professionals.",
      },
      {
        subHeading: "",
        para: "Expanding Specialized Services: The hospital intends to expand its specialized services to address the growing healthcare needs of the region. This includes the establishment of new departments, such as nephrology, endocrinology, and rheumatology, to provide comprehensive care for patients with complex medical conditions.",
      },
      {
        subHeading: "",
        para: "Improving Infrastructure: AIMS is committed to continuously improving its infrastructure to provide a safe and comfortable environment for patients and staff. This includes the renovation and expansion of existing facilities, the acquisition of modern medical equipment, and the implementation of advanced information technology systems.",
      },
      {
        subHeading: "",
        para: "Community Engagement: AIMS plans to increase its engagement with the community through outreach programs and public health initiatives. By raising awareness about preventive healthcare measures and providing essential medical services to underserved populations, the hospital aims to improve overall health outcomes in the region.",
      },
      {
        subHeading: "Conclusion",
        para: "The Abbas Institute of Medical Sciences (AIMS) Hospital in Muzaffarabad is a cornerstone of healthcare in Azad Jammu & Kashmir. Its establishment has brought significant improvements in access to quality medical services, medical education, and research in the region. Despite facing challenges, AIMS remains committed to its mission of providing comprehensive healthcare to the local population. With a focus on future growth and development, AIMS is poised to continue its vital role in enhancing the health and well-being of the people it serves.",
      },
    ],
  },
];

const About = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
      }}
    >
      <div className="main-page-ui">
        <AboutHero />
        <div className="aboutHome-section" id="services">
          {aboutArray?.map((item) => {
            return (
              <div className="aboutHome-title-content" key={item.id}>
                <h3 className="aboutHome-title">{item.heading}</h3>
                {item?.description?.map((innerItem, innerIndex) => {
                  return (
                    <div key={innerIndex}>
                      {innerItem?.subHeading && (
                        <h2 className="aboutHome-subheading">
                          {innerItem.subHeading}
                        </h2>
                      )}
                      <p className="aboutHome-aboutDescription">{innerItem.para}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default About;
