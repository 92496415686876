import React, { useState } from "react";
import "../../Styles/HomeCompStyles/HeroServiceComp.css";
import {
  faPlus,
  faHeartPulse,
  faStethoscope,
  faBone,
  faTeethOpen,
  faRadiation,
  faGaugeMed,
  faBookMedical,
  faSuitcaseMedical,
  faStar,
  faEye,
  faLungs,
  faBrain,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
const boxes = [
  {
    id: 1,
    name: "Pathology",
    desc: "Laboratory Services",
    logo: faVial,
    heading: "Pathology Department",
    details: [
      "Comprehensive diagnostic testing",
      "Blood and tissue sample analysis",
      "Clinical chemistry and hematology",
      "Histopathology and cytology services",
      "Microbiology and infectious disease testing",
      "Biopsy and cancer diagnosis",
      "Accurate and timely lab results",
    ],
  },
  {
    id: 2,
    name: "Pulmonology",
    desc: "Respiratory Care",
    logo: faLungs,
    heading: "Pulmonology Department",
    details: [
      "Comprehensive lung function testing",
      "Treatment for asthma and COPD",
      "Sleep apnea diagnosis and management",
      "Pulmonary rehabilitation programs",
      "Bronchoscopy and interventional pulmonology",
      "Chronic cough evaluation and management",
      "Advanced respiratory care services",
    ],
  },
  {
    id: 3,
    name: "Cardiology",
    desc: "Happy Patients",
    logo: faHeartPulse,
    heading: "Cardiology Department",
    details: [
      "Electrocardiogram (ECG)",
      "Echocardiogram",
      "Dobutamine Stress Echo (DSE)",
      "Transesophageal Echo (TEE)",
      "Exercise Tolerance Test (ETT/TMT)",
      "Halter monitor",
      "24 Hour Ambulatory BP monitor",
    ],
  },
  {
    id: 4,
    name: "Orthopedics",
    desc: "Emergency Service",
    logo: faStethoscope,
    heading: "Orthopedics Department",
    details: [
      "Orthopedic surgery and joint replacements",
      "Sports injury and trauma care",
      "Physical therapy and rehabilitation",
      "Spine and back pain solutions",
      "Arthritis and joint disorder treatment",
      "Orthopedic consultations",
      "Customized orthopedic care plans",
    ],
  },
  {
    id: 5,
    name: "Dentistry",
    desc: "Hospital Rooms",
    logo: faTeethOpen,
    heading: "Dentistry Department",
    details: [
      "Routine dental check-ups and cleanings",
      "Cosmetic dentistry and teeth whitening",
      "Oral surgery and extractions",
      "Dental implants and restorations",
      "Pediatric and family dentistry",
      "Gum disease treatment",
      "Orthodontic and braces options",
    ],
  },
  {
    id: 6,
    name: "Radiology",
    desc: "Hospital Rooms",
    logo: faRadiation,
    heading: "Radiology Department",
    details: [
      "Advanced diagnostic imaging services",
      "Mammography and breast health screening",
      "Interventional radiology procedures",
      "Virtual colonoscopy and body scans",
      "Radiology consultations",
      "Fast and accurate imaging results",
      "State-of-the-art radiology technology",
    ],
  },
  {
    id: 7,
    name: "Urology",
    desc: "Hospital Rooms",
    logo: faGaugeMed,
    heading: "Urology Department",
    details: [
      "Urinary tract and kidney evaluations",
      "Urologic surgery and stone removal",
      "Men's and women's urological health",
      "Prostate and bladder care",
      "Incontinence and pelvic floor therapy",
      "Urological consultations",
      "Comprehensive urology solutions",
    ],
  },
  {
    id: 8,
    name: "Medicine",
    desc: "Hospital Rooms",
    logo: faBookMedical,
    heading: "Medicine Department",
    details: [
      "Primary care and internal medicine",
      "Chronic disease management and prevention",
      "Immunizations and wellness checks",
      "Holistic and integrative medicine",
      "Geriatric and pediatric medicine",
      "Health education and lifestyle coaching",
      "Individualized medical treatment plans",
    ],
  },
  {
    id: 9,
    name: "Neurology",
    desc: "Expert Doctors",
    logo: faPlus,
    heading: "Neurology Department",
    details: [
      "Expert neurological consultations",
      "Comprehensive brain and nerve care",
      "Advanced neuroimaging services",
      "Treatment for epilepsy and seizures",
      "Memory and cognitive assessments",
      "Pediatric neurology expertise",
      "Movement disorders management",
    ],
  },

  {
    id: 10,
    name: "Neurology",
    desc: "Brain and Nerve Care",
    logo: faBrain,
    heading: "Neurology Department",
    details: [
      "Treatment for neurological disorders",
      "Stroke and seizure management",
      "Multiple sclerosis care",
      "Headache and migraine treatment",
      "Neuromuscular disorder treatment",
      "Comprehensive neurological exams",
      "Cutting-edge neurological research",
    ],
  },
  {
    id: 11,
    name: "Ophthalmology",
    desc: "Vision and Eye Care",
    logo: faEye,
    heading: "Ophthalmology Department",
    details: [
      "Comprehensive eye exams",
      "Cataract and LASIK surgery",
      "Glaucoma diagnosis and treatment",
      "Retina and macular disease care",
      "Pediatric ophthalmology services",
      "Contact lens fitting and care",
      "Advanced vision correction solutions",
    ],
  },
  {
    id: 12,
    name: "See More",
    desc: "Explore Our Services",
    logo: faSuitcaseMedical,
    heading: "Explore Our Services",
    details: [
      "Explore additional healthcare options",
      "Specialized medical services for all needs",
      "Discover a world of medical solutions",
      "Find the right care for you",
      "Comprehensive healthcare offerings",
      "More than meets the eye",
      "Healthcare beyond expectations",
    ],
  },
];

function HeroServiceComp() {
  const [currentData, setCurrentData] = useState(boxes[0]);
  const [activeBoxId, setActiveBoxId] = useState(boxes[0].id);
  const navigate = useNavigate();
  return (
    <div className="heroServices">
      <h3 className="homeHero-title">DEPARTMENTS AND SERVICES</h3>
      <div className="heroServices-section">
        <div className="heroServicesLeft-section">
          {boxes?.map((item) => {
            return (
              <div
                className="heroServices-div"
                key={item?.id}
                onClick={() => {
                  setCurrentData(item);
                  setActiveBoxId(item.id);
                  if (item?.id === 12) {
                    navigate("/departments");
                  }
                }}
                style={{
                  backgroundColor:
                    item.id === activeBoxId ? "#1d4d85" : "white",
                  color: item.id !== activeBoxId ? "#1d4d85" : "white",
                }}
              >
                <FontAwesomeIcon
                  className="info-fa-icon"
                  icon={item?.logo}
                  style={{
                    color: item.id !== activeBoxId ? "#1d4d85" : "white",
                  }}
                />
                <p
                  className="heroServices-description"
                  style={{
                    color: item.id !== activeBoxId ? "#1d4d85" : "white",
                  }}
                >
                  {item?.name}
                </p>
              </div>
            );
          })}
        </div>
        <div className="heroServicesRight-section">
          <h1 className="heroServices-title">{currentData?.heading}</h1>
          <div>
            {currentData?.details?.map((innerItem, index) => {
              return (
                <p key={index} className="heroServices-description-2">
                  <FontAwesomeIcon
                    icon={faStar}
                    style={{ color: "#1d4d85", marginRight: "10px" }}
                  />
                  {innerItem}
                </p>
              );
            })}
          </div>
          <button
            className="homeHero-btn-moreinfo"
            onClick={() => {
              navigate("/departments");
            }}
          >
            More Info
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeroServiceComp;
