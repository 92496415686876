import React from "react";
import "../Styles/LegalDocs.css";

function PrivacyPolicy() {
  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">PRIVACY POLICY</h1>

      <div className="legal-text-content">
        {/* <p className="legal-title">General Info</p> */}
        <p className="legal-description">
          AIMS Hospital (hereinafter referred to as "AIMS," "we," "us," or
          "our") is committed to protecting your privacy. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your information
          when you visit our website [insert website URL], use our services, or
          interact with us in any manner. By accessing or using our services,
          you agree to the terms of this Privacy Policy.
        </p>

        <p className="legal-subtitle" style={{ fontWeight: "bold" }}>
          Information We Collect
        </p>
        <p className="legal-subtitle" style={{ fontWeight: "bold" }}>
          Personal Information
        </p>
        <p className="legal-description">
          When you visit our website, register for an account, make an
          appointment, or use our services, we may collect personal information
          that can identify you, such as your name, email address, phone number,
          date of birth, gender, and address. Additionally, we may collect
          health-related information such as medical history, treatment records,
          and insurance information.
        </p>
        <p className="legal-subtitle" style={{ fontWeight: "bold" }}>
          Non-Personal Information
        </p>
        <p className="legal-description">
          We also collect non-personal information that does not directly
          identify you. This may include demographic data, browser type, IP
          address, operating system, and usage data such as the pages you visit
          and the duration of your visit.
        </p>
        <p className="legal-subtitle" style={{ fontWeight: "bold" }}>
          How We Collect Information
        </p>
        <p className="legal-subtitle" style={{ fontWeight: "bold" }}>
          Direct Collection
        </p>
        <p className="legal-description">
          We collect personal information directly from you when you provide it
          to us. This includes when you fill out forms, register for an account,
          make an appointment, or communicate with us.
        </p>

        <p className="legal-subtitle">Indirect Collection</p>
        <p className="legal-description">
          We may collect non-personal information automatically through cookies,
          web beacons, and other tracking technologies. This helps us understand
          how you use our website and improve your experience.
        </p>

        <p className="legal-subtitle">Use of Your Information</p>
        <p className="legal-subtitle">Providing Services</p>
        <p className="legal-description">
          We use your personal information to provide the services you request,
          such as scheduling appointments, managing your medical records, and
          processing payments. Your information is also used to communicate with
          you about your treatment, appointments, and other health-related
          matters.
        </p>
        <p className="legal-subtitle">Improving Our Services</p>
        <p className="legal-description">
          We may use non-personal information to improve our website, services,
          and user experience. This data helps us understand how our services
          are used and identify areas for improvement.
        </p>
        <p className="legal-subtitle">Marketing and Communication</p>
        <p className="legal-description">
          With your consent, we may use your personal information to send you
          promotional materials, newsletters, and other communications. You can
          opt out of these communications at any time.
        </p>
        <p className="legal-subtitle">Legal and Regulatory Compliance</p>
        <p className="legal-description">
          We may use or disclose your information to comply with applicable
          laws, regulations, legal processes, or government requests. This
          includes sharing information with law enforcement or other authorities
          if required by law.
        </p>
        <p className="legal-subtitle">Sharing Your Information</p>
        <p className="legal-subtitle">With Service Providers</p>
        <p className="legal-description">
          We may share your information with third-party service providers who
          assist us in operating our website, providing services, or conducting
          our business. These service providers are required to protect your
          information and use it only for the purposes we specify.
        </p>
        <p className="legal-subtitle">With Affiliates and Partners</p>
        <p className="legal-description">
          We may share your information with our affiliates and partners to
          offer you additional services or benefits. These entities are bound by
          similar privacy obligations.
        </p>
        <p className="legal-subtitle">For Legal Reasons</p>
        <p className="legal-description">
          We may disclose your information if we believe it is necessary to
          investigate, prevent, or take action regarding illegal activities,
          suspected fraud, or violations of our terms and policies.
        </p>
        <p className="legal-subtitle">In Connection with Business Transfers</p>
        <p className="legal-description">
          If AIMS is involved in a merger, acquisition, or sale of assets, your
          information may be transferred as part of the transaction. We will
          notify you of any such changes and any choices you may have regarding
          your information.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
