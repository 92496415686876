import React from "react";
import "../../Styles/About.css";
import HorizontalScroll from "../HorizontalScroll";
import { useNavigate } from "react-router-dom";

function HomeGallery() {
  const navigate = useNavigate();
  const images = [
    {
      id: 1,
      name: "Pathology",
      img: require("../../Assets/Gallery/Pathology/10.jpeg"),
    },
    {
      id: 2,
      name: "Pathology",
      img: require("../../Assets/Gallery/Pathology/2.jpeg"),
    },
    {
      id: 3,
      name: "Surgical",
      img: require("../../Assets/Gallery/Surgical/1.webp"),
    },
    {
      id: 4,
      name: "Surgical",
      img: require("../../Assets/Gallery/Surgical/2.webp"),
    },
    {
      id: 5,
      name: "Cardiology",
      img: require("../../Assets/Gallery/Cardiology/1.webp"),
    },
    {
      id: 6,
      name: "Cardiology",
      img: require("../../Assets/Gallery/Cardiology/2.webp"),
    },
    {
      id: 7,
      name: "Children ward",
      img: require("../../Assets/Gallery/Children's-Ward/1.webp"),
    },
    {
      id: 8,
      name: "Children ward",
      img: require("../../Assets/Gallery/Children's-Ward/2.webp"),
    },

    {
      id: 9,
      name: "Gynae",
      img: require("../../Assets/Gallery/Gynae/1.webp"),
    },
    {
      id: 10,
      name: "ICU",
      img: require("../../Assets/Gallery/ICU/1.webp"),
    },
    {
      id: 11,
      name: "Children ward",
      img: require("../../Assets/Gallery/ICU/2.webp"),
    },
    {
      id: 12,
      name: "Nephro",
      img: require("../../Assets/Gallery/Nephro/1.webp"),
    },
    {
      id: 13,
      name: "Nephro",
      img: require("../../Assets/Gallery/Nephro/2.webp"),
    },
    {
      id: 14,
      name: "OPDC",
      img: require("../../Assets/Gallery/OPDC/1.webp"),
    },
    {
      id: 15,
      name: "OPDC",
      img: require("../../Assets/Gallery/OPDC/2.webp"),
    },
    {
      id: 16,
      name: "Emergency",
      img: require("../../Assets/Gallery/Emergency/1.webp"),
    },
    {
      id: 17,
      name: "Emergency",
      img: require("../../Assets/Gallery/Emergency/2.webp"),
    },
  ];

  return (
    <div className="about-div" id="about">
      <h3 className="about-title">GALLERY</h3>
      <div className="about-images">
        <HorizontalScroll images={images} />
      </div>
      <button
        className="galleryHero-btn"
        onClick={() => {
          navigate("/gallery");
        }}
      >
        View Gallery
      </button>
    </div>
  );
}

export default HomeGallery;
