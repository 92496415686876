import React from "react";
import Doctor from "../../Assets/banner/about.png";
import "../../Styles/Hero.css";

function AboutHero() {
  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-Title">
            Abbas Institute of Medical Sciences (AIMS)
          </p>
          <p className="text-headline">Our Mission</p>
          <p className="text-descritpion">
            Abbas Institute of Medical Sciences is committed to maintain and
            improve services to the community according to the highest
            attainable quality standards and to provide a suitable
            environmentfor educating and training of headlthcare professionals.
          </p>
          <p className="text-headline">Our Vision</p>
          <p className="text-descritpion">
            AIMS will be a truly autonomous and leading edge ‘Centre of
            Excellence’ for patient care, training of health care providers, and
            medical research activities. It will serve as a role model for other
            public and private health care facilities.
          </p>
        </div>
        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>
    </div>
  );
}

export default AboutHero;
