import React, { useEffect, useState } from "react";
import "../Styles/NewsList.css";
import logo from "../Assets/banner/banner.png";
import { Link } from "react-router-dom";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewsPage = () => {
  const [listData, setListData] = useState([]);

  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://aims-ajk.com/dashboard/api/news", requestOptions)
      .then((response) => response.json()) // Parse the JSON response
      .then((result) => {
        const sortedList = result?.news.sort((a, b) => b.id - a.id);
        setListData(sortedList);
      }) // Update the state with fetched data
      .catch((error) => console.error(error));
  };
  console.log("listData", listData);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
      }}
    >
      <div className="news-page">
        <div style={{ width: "100%", height: "50vh" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={logo}
            alt="News"
          />
        </div>
        <h2 className="news-h2-heading">NEWS & ANNOUNCEMENT</h2>
        <div className="news-section-list">
          {listData?.map((item, index) => (
            <div className="news-section-list-item" key={index}>
              <h4 style={{ marginBottom: "10px" }}>{item.name}</h4>
              <p>
                <FontAwesomeIcon
                  className="info-fa-icon"
                  icon={faCalendarDays}
                />{" "}
                {item.date}
              </p>
              <div className="image-side-list-item">
                <img src={item?.image} alt="News" />
              </div>
              <Link
                className="btn-side-list-item"
                to={`/news/${item.name}-${item.id}`}
              >
                Read more
              </Link>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "gray",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
