import React from "react";
import "../../src/Styles/Achievements.css"

const Achievements = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
      }}
    >
      <div className="Achievements-section">
        <div className="Achievements-title-content">
          <h3 className="Achievements-title">
            <span>ACHIEVEMENTS</span>
          </h3>
        </div>

      </div>
    </div>
  );
};

export default Achievements;
