import React, { useEffect, useState } from "react";
import "../../Styles/HomeCompStyles/NewsComp.css";
import "../../Styles/HomeCompStyles/NewsCard.css";
import { Link, useNavigate } from "react-router-dom";

function NewsComp() {
  const navigate = useNavigate();
  const [listData, setListData] = useState();

  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://aims-ajk.com/dashboard/api/news", requestOptions)
      .then((response) => response.json()) // Parse the JSON response
      .then((result) => {
        const sortedList = result?.news.sort((a, b) => b.id - a.id);
        const topThree = sortedList.slice(0, 3);
        setListData(topThree);
      }) // Update the state with fetched data
      .catch((error) => console.error(error));
  };

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  }

  return (
    <div className="NewsComp-section" id="NewsComp">
      <h3 className="NewsComp-title">LATEST NEWS</h3>
      <div className="NewsComp-cards-content">
        {listData?.map((item, index) => {
          console.log("listData", item?.image);
          return (
            <div className="News-card" key={index}>
              <img src={item.image} alt={item.name} className="News-card-img" />
              <p className="News-card-name">{truncateString(item?.name, 60)}</p>
              <p className="News-card-Desc">{item?.date}</p>
              <Link
                className="News-card-Info"
                to={`/news/${item.name}-${item.id}`}
                alt={""}
               
              >
                Read More
              </Link>
            </div>
          );
        })}
      </div>
      <button
        className="newsHero-btn"
        onClick={() => {
          navigate("/news");
        }}
      >
        View All News
      </button>
    </div>
  );
}

export default NewsComp;
