import React from "react";
import SolutionStep from "../SolutionStep";
import "../../Styles/About.css";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/banner/who-2.png";

function About() {
  const navigate = useNavigate();
  const longText = `The Abbas Institute of Medical Sciences (AIMS) is a prominent healthcare institution located in Muzaffarabad, the capital of Azad Jammu & Kashmir, Pakistan. Established in 1992, AIMS is a teaching hospital affiliated with Azad Jammu Kashmir Medical College. It plays a crucial role in providing comprehensive healthcare services to the local population and serves as a vital training ground for medical professionals. This essay provides an in-depth look at the history, structure, services, and impact of AIMS Hospital, highlighting its significance in the region's healthcare landscape.`;

  return (
    <div className="about-div" id="about">
      <h3 className="about-title">WHO WE ARE</h3>
      <p className="about-text-heading">{longText}</p>
      <div className="about-text-content">
        <div className="about-left-content">
          <h4 className="about-text-title">Your Solutions</h4>

          <SolutionStep
            title="Choose a Specialist"
            description="Find your perfect specialist and book with ease at Health Plus. Expert doctors prioritize your health, offering tailored care."
          />

          <SolutionStep
            title="Make a Schedule"
            description="Choose the date and time that suits you best, and let our dedicated team of medical professionals ensure your well-being with personalized care."
          />

          <SolutionStep
            title="Get Your Solutions"
            description="Our experienced doctors and specialists are here to provide expert advice and personalized treatment plans, helping you achieve your best possible health."
          />
          <button
            className="homeHero-h-btn"
            onClick={() => {
              navigate("/about");
            }}
          >
            More Info
          </button>
        </div>
        <div className="about-right-content">
          <img
            src={logo}
            style={{
              width: "100%",
              height: "100%",
              // Add any additional styles here
            }}
            alt="News Logo"
          />
        </div>
      </div>
    </div>
  );
}

export default About;
