// components/HorizontalScroll.js
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

// Styled component for the scroll container
const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
`;

// Styled component for each image container
const ImageContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

// Styled component for the images
const Image = styled.img`
  max-height: 200px;
`;

// Styled component for the scroll indicator
const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background: #007bff;
  transition: width 0.3s;
`;

const HorizontalScroll = ({ images }) => {
  const scrollContainerRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollLeft(scrollContainerRef.current.scrollLeft);
      setScrollWidth(scrollContainerRef.current.scrollWidth);
      setClientWidth(scrollContainerRef.current.clientWidth);
    };

    const container = scrollContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollStart(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = x - startX;
    scrollContainerRef.current.scrollLeft = scrollStart - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const scrollIndicatorWidth = (scrollLeft / (scrollWidth - clientWidth)) * 100;

  return (
    <ScrollContainer
      ref={scrollContainerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {images?.map((src, index) => (
        <ImageContainer key={index}>
          <Image
            src={src?.img}
            alt={`image-${index}`}
            style={{ width: "275px", height: "250px" }}
          />
          <p
            style={{ fontSize: "20px", textAlign: "center", marginTop: "10px", fontFamily: "poppins" }}
          >
            {src.name}
          </p>
        </ImageContainer>
      ))}
      <ScrollIndicator style={{ width: `${scrollIndicatorWidth}%` }} />
    </ScrollContainer>
  );
};

export default HorizontalScroll;
