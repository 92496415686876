import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DepartmentCard(props) {
  return (
    <div className="dep-cards">
      <FontAwesomeIcon className="dep-fa-icon" icon={props.icon} />
      <p className="dep-card-title">{props.title}</p>
      <p className="dep-card-description">{props.description}</p>
    </div>
  );
}

export default DepartmentCard;
