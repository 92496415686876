import React from "react";
import Doctors from "../Components/HomePageComp/Doctors";
import HeroBox from "../Components/HomePageComp/HeroBox";
import HomeHeroComp from "../Components/HomePageComp/HomeHeroComp";
import HeroServiceComp from "../Components/HomePageComp/HeroServiceComp";
import NewsComp from "../Components/HomePageComp/NewsComp";
import About from "../Components/HomePageComp/About";
import "../Styles/HomeCompStyles/HeroServiceComp.css";
import HomeGallery from "../Components/HomePageComp/HomeGallery";

function Home() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <HomeHeroComp />
      <div className="main-page-ui">
        <HeroBox />
        <About />
        <HeroServiceComp />
        <Doctors />
        <HomeGallery />
        <NewsComp />
      </div>
    </div>
  );
}

export default Home;
