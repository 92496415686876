// src/Pages/Contact.js
import React from "react";
import AboutImageCard from "../Components/AboutImageCard";
import "../Styles/Gallery.css";

const galleryImages = [
  {
    id: 1,
    depName: "Department of Pathology",
    para: "The Pathology Department plays a crucial role in diagnosing and monitoring diseases through comprehensive laboratory testing. It offers a wide range of services including blood and tissue sample analysis, microbiology testing, and histopathology. With state-of-the-art technology, the department ensures accurate and timely results to support effective patient care and treatment planning.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Pathology/10.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/0.jpeg"),
        depImageName: "Pathology",
      },

      {
        depImage: require("../Assets/Gallery/Pathology/2.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/3.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/11.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/9.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/6.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/5.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/1.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/4.jpeg"),
        depImageName: "Pathology",
      },

      {
        depImage: require("../Assets/Gallery/Pathology/7.jpeg"),
        depImageName: "Pathology",
      },
      {
        depImage: require("../Assets/Gallery/Pathology/8.jpeg"),
        depImageName: "Pathology",
      },
    ],
  },
  {
    id: 2,
    depName: "Surgical Department",
    para: "Our Surgical Department provides a comprehensive range of Surgical services with advanced techniques and state-of-the-art facilities. Our skilled surgeons and support team perform various procedures, from elective surgeries to emergency operations, ensuring high standards of care, precision, and patient safety throughout the Surgical process.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Surgical/6.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/1.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/2.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/3.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/4.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/5.webp"),
        depImageName: "Surgical",
      },

      {
        depImage: require("../Assets/Gallery/Surgical/9.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/10.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/11.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/12.webp"),
        depImageName: "Surgical",
      },
      {
        depImage: require("../Assets/Gallery/Surgical/13.webp"),
        depImageName: "Surgical",
      },
    ],
  },
  {
    id: 3,
    depName: "Department of Orthopedics",
    para: "Our Orthopedics department specializes in the diagnosis, treatment, and management of musculoskeletal disorders. We provide comprehensive care for bone, joint, and muscle issues, including injuries, arthritis, and congenital conditions. Our skilled Orthopedic surgeons and staff are dedicated to restoring mobility and improving quality of life for our patients.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Surgical/8.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/1.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/2.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/3.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/4.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/5.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/6.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/7.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/8.webp"),
        depImageName: "Orthopedics",
      },
      {
        depImage: require("../Assets/Gallery/Ortho/10.webp"),
        depImageName: "Orthopedics",
      },
    ],
  },
  
  {
    id: 4,
    depName: "Emergency Department",
    para: "Our Emergency Room (ER) offers 24/7 immediate medical care for urgent and life-threatening conditions. With state-of-the-art equipment and a dedicated team of emergency medicine specialists, we are prepared to handle a wide range of medical emergencies, ensuring rapid assessment and treatment for all patients.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Emergency/10.webp"),
        depImageName: "Emergency",
      },
      {
        depImage: require("../Assets/Gallery/Emergency/1.webp"),
        depImageName: "Emergency",
      },
      {
        depImage: require("../Assets/Gallery/Emergency/3.webp"),
        depImageName: "Emergency",
      },
      {
        depImage: require("../Assets/Gallery/Emergency/4.webp"),
        depImageName: "Emergency",
      },
      {
        depImage: require("../Assets/Gallery/Emergency/5.webp"),
        depImageName: "Emergency",
      },
      {
        depImage: require("../Assets/Gallery/Emergency/6.webp"),
        depImageName: "Emergency",
      },
      {
        depImage: require("../Assets/Gallery/Emergency/7.webp"),
        depImageName: "Emergency",
      },
      {
        depImage: require("../Assets/Gallery/Emergency/8.webp"),
        depImageName: "Emergency",
      },
      {
        depImage: require("../Assets/Gallery/Emergency/9.webp"),
        depImageName: "Emergency",
      },
      
    ],
  },
  {
    id: 5,
    depName: "Children's Ward",
    para: "Our Children's-Ward Ward is a nurturing and Children's-Ward-friendly environment designed to provide specialized medical care for infants, Children's-Wardren, and adolescents. Our compassionate pediatricians and staff are dedicated to ensuring the health and well-being of our young patients, offering personalized treatment and support.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Children's-Ward/1.webp"),
        depImageName: "Children's-Ward",
      },
      {
        depImage: require("../Assets/Gallery/Children's-Ward/2.webp"),
        depImageName: "Children's-Ward",
      },
      {
        depImage: require("../Assets/Gallery/Children's-Ward/3.webp"),
        depImageName: "Children's-Ward",
      },
      {
        depImage: require("../Assets/Gallery/Children's-Ward/4.webp"),
        depImageName: "Children's-Ward",
      },
      {
        depImage: require("../Assets/Gallery/Children's-Ward/5.webp"),
        depImageName: "Children's-Ward",
      },
      {
        depImage: require("../Assets/Gallery/Children's-Ward/6.webp"),
        depImageName: "Children's-Ward",
      },
      {
        depImage: require("../Assets/Gallery/Children's-Ward/7.webp"),
        depImageName: "Children's-Ward",
      },
      {
        depImage: require("../Assets/Gallery/Children's-Ward/8.webp"),
        depImageName: "Children's-Ward",
      },
      {
        depImage: require("../Assets/Gallery/Children's-Ward/9.webp"),
        depImageName: "Children's-Ward",
      },
    ],
  },
 
  {
    id: 6,
    depName: "Executive Director AIMS",
    para: "The Executive Director of AIMS oversees all strategic and operational aspects of the institution, ensuring excellence in medical education, research, and patient care. They lead initiatives to enhance the quality of healthcare services and drive innovation within the organization. With a focus on leadership and collaboration, the Executive Director plays a key role in guiding AIMS towards achieving its mission and vision.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/EDAIMS/1.webp"),
        depImageName: "EDAIMS",
      },
      {
        depImage: require("../Assets/Gallery/EDAIMS/2.webp"),
        depImageName: "EDAIMS",
      },
      {
        depImage: require("../Assets/Gallery/EDAIMS/3.webp"),
        depImageName: "EDAIMS",
      },
      {
        depImage: require("../Assets/Gallery/EDAIMS/4.webp"),
        depImageName: "EDAIMS",
      },
      {
        depImage: require("../Assets/Gallery/EDAIMS/5.webp"),
        depImageName: "EDAIMS",
      },
      {
        depImage: require("../Assets/Gallery/EDAIMS/6.webp"),
        depImageName: "EDAIMS",
      },
    ],
  },
  {
    id: 7,
    depName: "Department of Cardiology",
    para: "Our Cardiology is dedicated to providing comprehensive cardiac care with state-of-the-art facilities and expert cardiologists. We offer specialized treatments and monitoring for patients with heart conditions, ensuring the highest standards of care and support for optimal heart health.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Cardiology/1.webp"),
        depImageName: "Cardiology",
      },
      {
        depImage: require("../Assets/Gallery/Cardiology/2.webp"),
        depImageName: "Cardiology",
      },
      {
        depImage: require("../Assets/Gallery/Cardiology/3.webp"),
        depImageName: "Cardiology",
      },
      {
        depImage: require("../Assets/Gallery/Cardiology/4.webp"),
        depImageName: "Cardiology",
      },
      {
        depImage: require("../Assets/Gallery/Cardiology/5.webp"),
        depImageName: "Cardiology",
      },
      {
        depImage: require("../Assets/Gallery/Cardiology/6.webp"),
        depImageName: "Cardiology",
      },
    ],
  },
  {
    id: 8,
    depName: "Medical Department",
    para: "The Medical Ward at AIMS Hospital offers a wide range of healthcare services for the diagnosis, treatment, and management of various medical conditions. Our team of skilled physicians, nurses, and support staff work collaboratively to provide personalized care, ensuring optimal health outcomes for all our patients.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Medical/2.webp"),
        depImageName: "Medical",
      },
      {
        depImage: require("../Assets/Gallery/Medical/3.webp"),
        depImageName: "Medical",
      },
      {
        depImage: require("../Assets/Gallery/Medical/4.webp"),
        depImageName: "Medical",
      },
      {
        depImage: require("../Assets/Gallery/Medical/6.webp"),
        depImageName: "Medical",
      },

      {
        depImage: require("../Assets/Gallery/Medical/8.webp"),
        depImageName: "Medical",
      },
    ],
  },
  {
    id: 9,
    depName: "Department of Nephrology",
    para: "Our Nephrology department specializes in the diagnosis and treatment of kidney-related conditions. We offer comprehensive care for chronic kidney disease, dialysis, and other renal issues. Our expert nephrologists and dedicated staff provide personalized treatment plans to manage and improve kidney health effectively.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Nephro/1.webp"),
        depImageName: "Nephro",
      },
      {
        depImage: require("../Assets/Gallery/Nephro/2.webp"),
        depImageName: "Nephro",
      },
      {
        depImage: require("../Assets/Gallery/Nephro/3.webp"),
        depImageName: "Nephro",
      },
      {
        depImage: require("../Assets/Gallery/Nephro/4.webp"),
        depImageName: "Nephro",
      },
    ],
  },
  {
    id: 10,
    depName: "Intensive Care Unit",
    para: "Our Intensive Care Unit (ICU) is equipped with the latest medical technology and staffed by highly trained critical care specialists. We provide advanced monitoring and treatment for patients with severe or life-threatening conditions, ensuring comprehensive and continuous care to support recovery and stabilization.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/ICU/1.webp"),
        depImageName: "ICU",
      },
      {
        depImage: require("../Assets/Gallery/ICU/2.webp"),
        depImageName: "ICU",
      },
      {
        depImage: require("../Assets/Gallery/ICU/3.webp"),
        depImageName: "ICU",
      },
      {
        depImage: require("../Assets/Gallery/ICU/4.webp"),
        depImageName: "ICU",
      },
    ],
  },
  {
    id: 11,
    depName: "Department of Pulmonology",
    para: "Our Pulmonology department focuses on the diagnosis and treatment of respiratory and lung conditions. We provide expert care for diseases such as asthma, COPD, and lung infections. Our team of Pulmonologynologists and respiratory therapists work together to improve lung health and manage chronic respiratory issues effectively.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Pulmonology/3.webp"),
        depImageName: "Pulmonology",
      },
      {
        depImage: require("../Assets/Gallery/Pulmonology/1.webp"),
        depImageName: "Pulmonology",
      },
      {
        depImage: require("../Assets/Gallery/Pulmonology/2.webp"),
        depImageName: "Pulmonology",
      },
    ],
  },

  {
    id: 12,
    depName: "Department of Radiology",
    para: "Our Radiology department is dedicated to diagnosing and treating kidney-related disorders. We offer expert care for conditions such as chronic kidney disease, kidney stones, and hypertension. Our nephrologists and healthcare professionals provide personalized treatment plans and support to manage kidney health and enhance overall well-being.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Radiology/1.png"),
        depImageName: "Radiology",
      },
      {
        depImage: require("../Assets/Gallery/Radiology/2.webp"),
        depImageName: "Radiology",
      },
      {
        depImage: require("../Assets/Gallery/Radiology/3.webp"),
        depImageName: "Radiology",
      },
    ],
  },

  {
    id: 13,
    depName: "Outpatient Department",
    para: "Our Outpatient Department (OPD) provides accessible, convenient healthcare services for patients who do not require overnight stays. We offer a range of consultations, diagnostic services, and follow-up care, ensuring effective treatment and support in a comfortable and efficient setting.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/OPDC/1.webp"),
        depImageName: "OPDC",
      },
      {
        depImage: require("../Assets/Gallery/OPDC/2.webp"),
        depImageName: "OPDC",
      },
    ],
  },

  {
    id: 14,
    depName: "Operating Theater",
    para: "Our Operating Theater (OT) is equipped with cutting-edge Surgical technology and staffed by highly skilled surgeons and anesthetists. We provide a sterile, safe environment for a wide range of Surgical procedures, ensuring precision and optimal outcomes through meticulous care and advanced medical techniques.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/OT/1.webp"),
        depImageName: "OT",
      },
      {
        depImage: require("../Assets/Gallery/OT/2.webp"),
        depImageName: "OT",
      },
    ],
  },

  {
    id: 15,
    depName: "Department of Gynaecology",
    para: "Our Gynaecology department provides comprehensive care for women's reproductive health, including routine check-ups, prenatal care, and treatment for various Gynaecological conditions. Our experienced Gynaecologists and support staff are committed to offering personalized, compassionate care to ensure the health and well-being of every patient.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Gynae/1.webp"),
        depImageName: "Gynae",
      },
    ],
  },

  {
    id: 16,
    depName: "Department of Urology",
    para: "Our Urology department specializes in the diagnosis and treatment of urinary tract and male reproductive system disorders. We offer advanced care for conditions such as kidney stones, prostate issues, and urinary incontinence. Our expert urologists are dedicated to providing effective treatments and improving patient quality of life.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Urology/1.webp"),
        depImageName: "Urology",
      },
    ],
  },
  {
    id: 17,
    depName: "Department of Virology",
    para: "Our Virology department focuses on the study, diagnosis, and treatment of viral infections. We offer advanced diagnostic testing and tailored treatment plans for a wide range of viral diseases. Our team of virologists and healthcare professionals is committed to managing and controlling viral infections to safeguard patient health.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Virology/1.webp"),
        depImageName: "Virology",
      },
    ],
  },
  {
    id: 17,
    depName: "Department of Neurology",
    para: "The Neurology Department specializes in diagnosing and treating disorders of the brain, nerves, and spinal cord. It offers advanced care for conditions such as epilepsy, stroke, and movement disorders, supported by cutting-edge neuroimaging and expert consultations. The department is dedicated to providing comprehensive neurological care, ensuring optimal outcomes for patients.",
    depImages: [
      {
        depImage: require("../Assets/Gallery/Medical/7.webp"),
        depImageName: "Medical",
      },
    ],
  },
];

const Gallery = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
      }}
    >
      <div className="gallery-section">
        <div className="gallery-title-content">
          <h3 className="gallery-title">
            <span>GALLERY</span>
          </h3>
        </div>

        {galleryImages?.map((item, index) => {
          return (
            <div className="gallery-cards" key={index}>
              <h2 className="gallery-subheading">{item?.depName}</h2>
              <p className="gallery-para">{item?.para}</p>
              <div className="gallery-cards-item">
                {item?.depImages?.map((innerItem, innerIndex) => {
                  return (
                    <AboutImageCard
                      key={innerIndex}
                      img={innerItem?.depImage}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gallery;
