import React from "react";
import "../../Styles/HomeCompStyles/HeroBox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserDoctor,
  faXRay,
  faFlask,
  faHeartbeat,
  faClinicMedical,
  faBuildingShield,
} from "@fortawesome/free-solid-svg-icons";

const boxes = [
  { id: 1, name: "40+", desc: "Expert Doctors", icon: faUserDoctor },
  { id: 2, name: "85k+", desc: "Happy Patients", icon: faXRay },
  { id: 3, name: "24/7", desc: "Emergency Service", icon: faFlask },
  { id: 4, name: "5+", desc: "Operation Theatres", icon: faHeartbeat },
  { id: 5, name: "300+", desc: "Hospital Beds", icon: faClinicMedical },
  { id: 5, name: "17+", desc: "Disciplines", icon: faBuildingShield },
];

function heroBoxBox() {
  return (
    <div className="heroBox-section">
      <div className="heroBox-header">
        <h3 className="heroBox-title-main">AIMS AT-A-GLANCE</h3>
        <p className="heroBox-subheading">
          At AIMS, we take pride in offering high quality facilities to ensure
          the best care for our patients.
        </p>
      </div>
      <div className="heroBox-section">
        {boxes?.map((item) => {
          return (
            <div className="heroBox-div" key={item?.id}>
              <span className="heroBox-card-icon">
                <FontAwesomeIcon
                  className="heroBox-fa-icon"
                  icon={item?.icon}
                />
              </span>
              <div>
                <h3 className="heroBox-title">{item?.name}</h3>
                <p className="heroBox-description">{item?.desc}</p>
              </div>
              <p className="heroBox-border"></p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default heroBoxBox;
