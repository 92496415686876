import React, { useEffect, useState } from "react";
import "../../Styles/NewsList.css";
import logo from "../../Assets/banner/banner.png";
import { useParams } from "react-router-dom";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const News = () => {
  const [newsItem, setNewsItem] = useState(null); // Initialize with null to indicate data is not yet fetched
  const { slug } = useParams();
  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://aims-ajk.com/dashboard/api/news", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const foundNews = result?.news.find((news) => `${news.name}-${news.id}` == slug);
        setNewsItem(foundNews);
      })
      .catch((error) => console.error(error));
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
      }}
    >
      <div className="news-page">
        <div style={{ width: "100%", height: "40vh", backgroundColor: "red" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={logo}
            alt="News"
          />
        </div>
        <div className="news-page-section">
          <h2
            style={{
              marginBottom: "10px",
              fontWeight: "normal",
              paddingTop: "20px",
            }}
          >
          </h2>
          <div className="news-section-list">
            <div className="news-section-list-item" key={newsItem?.id}>
              <h4 style={{ marginBottom: "10px" }}>{newsItem?.name}</h4>
              <p>
                <FontAwesomeIcon
                  className="info-fa-icon"
                  icon={faCalendarDays}
                />
                {newsItem?.date}
              </p>
              <div className="image-side-list-item">
                <img src={newsItem?.image} alt="News" />
              </div>
            </div>
            <div
              className="details-news"
              dangerouslySetInnerHTML={{ __html: newsItem?.description }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
