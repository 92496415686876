import React, { useEffect, useState } from "react";
import DoctorCard from "../DoctorCard";
import "../../Styles/HomeCompStyles/homeDoctor.css";
import { useNavigate } from "react-router-dom";

function Doctors() {
  const [doctorsList, setDoctorsList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDoctorsList();
  }, []);

  const getDoctorsList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://aims-ajk.com/dashboard/api/doctors", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const doctors = result?.doctors || [];
        // Filter the doctors
        const filtered = doctors.filter((doctor) =>
          [
            "Dr. Mumtaz Ahmed Khan",
            "Dr. Abdul Khalid Awan",
            "Dr. Zayad Afzal Kayani",
            "Dr. Sohail Khan Raja",
          ].includes(doctor.name)
        );

        setDoctorsList(filtered);
      })
      .catch((error) => console.error(error));
  };

  console.log("setDoctorsList", doctorsList);

  return (
    <div
      className="h-doctor-section"
      style={{ marginBottom: "1px" }}
      id="doctors"
    >
      <div className="dt-h-title-content" style={{ marginBottom: "10px" }}>
        <h3 className="dt-h-title">OUR LEADING MEDICAL PROFESSIONALS</h3>
        <p className="dt-h-description">
          Meet our exceptional team of specialist doctors, dedicated to
          providing top-notch healthcare services.
        </p>
      </div>

      <div className="dt-h-cards-content">
        {doctorsList?.map((item, index) => {
          return (
            <DoctorCard
              key={index}
              img={item?.image}
              name={item?.name}
              title={item?.title}
            />
          );
        })}
      </div>
      <button
        className="homeHero-h-btn"
        onClick={() => {
          navigate("/doctors");
        }}
      >
        View More Doctors
      </button>
    </div>
  );
}

export default Doctors;
