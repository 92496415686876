import React from "react";
// import "../../Styles/Footer.css";
import "../../Styles/navBar/Footer.css";
import logo from "../../Assets/icons/aims-white.svg";
import FooterLogo from "../Footer/FooterLogo";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                color: "white",
                letterSpacing: ".6px",
                textDecoration: "none",
                marginLeft: "5px",
              }}
              onClick={handleClick}
            >
              <div>
                <img
                  src={logo}
                  style={{ width: "300px", height: "70px", marginRight: "5px" }}
                  alt="Newsft-list-items"
                />
              </div>
            </h1>
            <p className="ft-description">
              AIMS Hospital in Muzaffarabad offers comprehensive healthcare
              services with advanced medical facilities and a dedicated team of
              professionals.
            </p>
          </div>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">General</p>
          <ul className="ft-list-items">
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/departments">Departments</Link>
            </li>
            <li>
              <Link to="/doctors">Doctors</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <Link to="/news">News</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-of-use">Terms of Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <Link to="https://www.google.com/maps?rlz=1C1CHBD_en-GBPK1104PK1104&gs_lcrp=EgZjaHJvbWUqDwgAEAAYFBiHAhjjAhiABDIPCAAQABgUGIcCGOMCGIAEMhIIARAuGBQYrwEYxwEYhwIYgAQyBggCEEUYOTIHCAMQABiABDIJCAQQLhgKGIAEMg0IBRAuGK8BGMcBGIAEMgYIBhBFGD0yBggHEEUYPdIBCDM2NDhqMGo0qAIAsAIA&um=1&ie=UTF-8&fb=1&gl=pk&sa=X&geocode=KeEurHP3neA4MRSq9froJ9YY&daddr=8FJ9%2BFFJ,+Muzaffarabad,+Azad+Jammu+and+Kashmir">
                Azad Jammu and Kashmir
              </Link>
            </li>
            <li>
              <Link to="mailto:support@aims-ajk.com">
                support@aims-ajk.com
              </Link>
            </li>
            <li>
              <Link to="tel:+05822-921016">Tel 05822-921016</Link>
            </li>
            <li>
              <Link to="tel:+05822-921900">Tel 05822-921900</Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="ft-copyright"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p>This site is powered by</p>
        <FooterLogo />
      </div>
    </div>
  );
}

export default Footer;
