import React from "react";
import {
  faEye,
  faProcedures,
  faBaby,
  faEarDeaf,
  faVenusDouble,
  faToilet,
  faBone,
  faXRay,
  faFlask,
  faTooth,
  faClinicMedical,
  faBrain,
  faLungsVirus,
  faNeuter,
  faHeartbeat,
  faAllergies,
  faWheelchair,
  faLungs,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Department.css";
import DepartmentCard from "../Components/DepartmentCard";

const depData = [
  {
    id: 1,
    heading: "SURGERY AND ALLIED",
    dep: [
      {
        department: "General Surgery",
        logo: faProcedures,
        description:
          "AIMS Hospital's General Surgery department offers comprehensive surgical care for various conditions, focusing on abdominal surgeries and trauma management.",
      },
      {
        department: "Paediatric Surgery",
        logo: faBaby,
        description:
          "Specializing in surgical care for infants and children, this department at AIMS Hospital provides expertise in treating pediatric surgical conditions with care and precision.",
      },
      {
        department: "ENT",
        logo: faEarDeaf,
        description:
          "AIMS Hospital's ENT department delivers specialized care for disorders affecting the ear, nose, and throat, including surgical and non-surgical treatments.",
      },
      {
        department: "Gynaecology/Obstetrics",
        logo: faVenusDouble,
        description:
          "Providing expert care in women's health, this department offers services ranging from routine gynecological exams to obstetric care during pregnancy and childbirth.",
      },
      {
        department: "Ophthalmology (Eye)",
        logo: faEye,
        description:
          "AIMS Hospital's Eye department focuses on diagnosing and treating eye conditions, offering surgical interventions and vision care services.",
      },
      {
        department: "Urology/Lithotripsy",
        logo: faToilet,
        description:
          "Specializing in urinary tract health, this department provides treatments for urological conditions and includes lithotripsy services for non-invasive kidney stone removal.",
      },
      {
        department: "Orthopaedics",
        logo: faBone,
        description:
          "AIMS Hospital's Orthopaedics department specializes in treating musculoskeletal disorders, offering surgical and non-surgical interventions to improve mobility and quality of life.",
      },
      {
        department: "Dental Surgery",
        logo: faTooth,
        description:
          "Specializes in dental care and surgeries, providing treatments for oral health issues at AIMS Hospital.",
      },
    ],
  },
  {
    id: 2,
    heading: "MEDICAL AND ALLIED",
    dep: [
      {
        department: "General Medicine",
        logo: faClinicMedical,
        description:
          "Provides primary and specialized medical care for various diseases and conditions.",
      },
      {
        department: "Neurology",
        logo: faBrain,
        description:
          "Focuses on diagnosing and treating disorders of the nervous system, including stroke and epilepsy.",
      },
      {
        department: "Nephrology/Dialysis",
        logo: faLungsVirus,
        description:
          "Specializes in kidney care, offering treatments for kidney diseases and dialysis services.",
      },
      {
        department: "Psychiatry",
        logo: faNeuter,
        description:
          "Provides diagnosis, treatment, and therapy for mental health disorders and conditions.",
      },
      {
        department: "Paediatrics",
        logo: faBaby,
        description:
          "Specializes in medical care for infants, children, and adolescents.",
      },
      {
        department: "Cardiology",
        logo: faHeartbeat,
        description:
          "Specializes in heart care and treatment of cardiovascular diseases at AIMS Hospital.",
      },
      {
        department: "Dermatology",
        logo: faAllergies,
        description:
          "Focuses on diagnosing and treating skin disorders and conditions.",
      },
      {
        department: "Physiotherapy",
        logo: faWheelchair,
        description:
          "Provides rehabilitative treatments and therapies to improve mobility and functionality.",
      },
      {
        department: "Oncology",
        logo: faLungs,
        description:
          "Specializes in diagnosing and treating cancer through chemotherapy, radiation therapy, and surgical interventions.",
      },
    ],
  },

  {
    id: 3,
    heading: "DIAGNOSTICS",
    dep: [
      {
        department: "Radiology",
        logo: faXRay,
        description:
          "Provides diagnostic imaging services including X-rays and ultrasonography at AIMS Hospital, Muzaffarabad.",
      },
      {
        department: "Pathology/Laboratory",
        logo: faFlask,
        description:
          "The Pathology Department provides precise diagnostic testing, including blood and tissue analysis, for accurate disease detection.",
      },
    ],
  },
  {
    id: 4,
    heading: "EMERGENCY",
    dep: [
      {
        department: "Emergency 24/7",
        logo: faHeartbeat,
        description:
          "The Emergency Department provides 24/7 urgent care, handling critical cases with immediate medical attention. Our team is always ready to respond to emergencies with expertise and efficiency.",
      },
    ],
  },
];
function Departments() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F7F7F7",
      }}
    >
      <div className="dep-section" id="services">
        {depData?.map((item, index) => {
          return (
            <div className="dep-title-content" key={index}>
              <h3 className="dep-title">
                <span>{item?.heading}</span>
              </h3>
              <div className="dep-cards-content">
                {item?.dep?.map((item) => {
                  return (
                    <DepartmentCard
                      key={item?.id}
                      title={item?.department}
                      description={item?.description}
                      icon={item?.logo}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Departments;
