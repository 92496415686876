import React from "react";
import "../Styles/LegalDocs.css";

const data1 = [
  {
    title: "",
    content: [
      `Welcome to AIMS Hospital ("AIMS," "we," "us," or "our"). By accessing our website [insert website URL], using our services, or engaging with us in any manner, you agree to comply with and be bound by the following terms and conditions ("Terms"). These Terms govern your use of our website, services, and interactions with AIMS Hospital. If you do not agree with these Terms, please do not use our services.`,
    ],
  },
  {
    title: "Definitions",
    bullets: [
      `"Services" refer to all services provided by AIMS Hospital, including but not limited to medical consultations, treatments, telemedicine, and the use of our website.`,
      `"User" or "You" refers to any individual who accesses our website or uses our services.`,
    ],
  },
  {
    title: "Use of Services",
    bullets: [
      "You must be at least 18 years old to use our services. By using our services, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.",
      "To access certain services, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary. You are responsible for safeguarding your account information and for any activities or actions under your account. AIMS Hospital will not be liable for any loss or damage arising from your failure to comply with these requirements.",
    ],
  },
  {
    title: "No Medical Advice",
    content: [
      "The content on our website is for informational purposes only and is not intended to substitute professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health providers with any questions you may have regarding a medical condition.",
    ],
  },
  {
    title: "No Guarantee",
    content: [
      "While we strive to provide high-quality healthcare services, we do not guarantee any specific results from the use of our services. Your use of our services is at your own risk.",
    ],
  },
  {
    title: "Fees",
    content: [
      "You agree to pay all fees and charges associated with your use of our services. Fees may vary based on the type and scope of services provided. All fees are non-refundable unless otherwise stated.",
    ],
  },
  {
    title: "Payment Methods",
    content: [
      "AIMS Hospital accepts various payment methods, including credit cards, debit cards, and other electronic payment methods. By providing payment information, you represent and warrant that you are authorized to use the designated payment method and authorize us to charge the specified amount to that payment method.",
    ],
  },
  {
    title: "Billing Errors",
    content: [
      "If you believe there has been an error in billing, you must contact us within 30 days of the billing date. We will investigate and, if necessary, correct any errors promptly.",
    ],
  },
  {
    title: "Privacy",
    content: [
      "Your use of our services is also governed by our Privacy Policy, which explains how we collect, use, and disclose your personal information. By using our services, you consent to our collection and use of your information as described in the Privacy Policy.",
    ],
  },
  {
    title: "Conclusion",
    content: [
      "By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. We appreciate your trust in AIMS Hospital and are committed to providing you with high-quality healthcare services.",
    ],
  },
];

function Termsofuse() {
  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">TERMS OF Services</h1>
      <div className="legal-text-content">
        {data1?.map((item, index) => {
          return (
            <div key={index}>
              <p className="legal-subtitle">{item.title}</p>
              {item?.content?.map((innerItem, innerIndex) => {
                return (
                  <p className="legal-description" key={innerIndex}>
                    {innerItem}
                  </p>
                );
              })}
              {item?.bullets?.map((innerItem, innerIndex) => {
                return (
                  <li className="legal-description" key={index}>
                    {innerItem}
                  </li>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Termsofuse;
