import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/navBar/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/icons/aims-black.svg";
import logo_ajk from "../../Assets/logos/ajk-logo.png";
import insta from "../../Assets/icons/insta.svg";
import facebook from "../../Assets/icons/fb.svg";
// import twitter from "../../Assets/icons/linked.svg";
import wiki from "../../Assets/icons/wiki-icon.svg";

function Navbar() {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const openNav = () => {
    setNav(!nav);
  };

  const faIcons = [
    {
      id: 1,
      name: insta,
      url: "https://www.instagram.com/explore/locations/620711184761201/aims-hospital-muzaffarabad/",
    },
    {
      id: 2,
      name: facebook,
      url: "https://www.facebook.com/p/Abbas-Institute-of-Medical-Sciences-AIMS-100069019606050/",
    },
    // { id: 3, name: twitter, url: "" },
    { id: 3, name: wiki, url: "https://en.wikipedia.org/wiki/Abbas_Institute_of_Medical_Sciences" },
  ];

  return (
    <div className="full-section">
      <div className="upper-section">
        <div className="image-section" onClick={handleClick}>
          <img
            src={logo}
            alt="News"
            style={{ width: "100%", height: "100%" }}
          />
          <img
            src={logo_ajk}
            alt="News"
            style={{ width: "100%", height: "100%", marginLeft: "10px" }}
          />
        </div>
        <div className="upper-right">
          <div className="icons-section">
            {faIcons?.map((item, index) => {
              return (
                // <div className="hamb-icon-div">
                <Link to={item.url}>
                  <img
                    key={index}
                    id={item?.id}
                    src={item?.name}
                    alt="News"
                    className="hamb-icon"
                  />
                </Link>
                // </div>
              );
            })}
          </div>
          <button
            className="Navbar-btn"
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact us
          </button>
        </div>
        {/* Mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar-close">
            <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
          </div>
          <ul className="mobile-navbar-links-ul">
            <li>
              <Link onClick={openNav} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/about">
                About
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/departments">
                Departments
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/doctors">
                Doctors
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/gallery">
                Gallery
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/achievements">
                Achievements
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/news">
                News & Events
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        {/* Hamburger Icon */}
        <div className="mobile-nav">
          <FontAwesomeIcon
            icon={faBars}
            onClick={openNav}
            className="hamb-icon"
            style={{ color: "#1d4d85" }}
          />
        </div>
      </div>
      <div className="lower-section">
        <ul className="mobile-navbar-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/departments">Departments</Link>
          </li>
          <li>
            <Link to="/doctors">Doctors</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/achievements">Achievements</Link>
          </li>

          <li>
            <Link to="/news">News & Events</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
